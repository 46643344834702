import React, { useContext } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import LanguageContext from "./LanguageContext"
import useTranslation from "../translations/translate"

import PreviewImage from "./assets/media/images/bending/twitter.jpg"
import config from "../translations/config.json"

const languages = Object.keys(config.enabledLanguages)

const SEO = ({ title, description, children, pageName }) => {
  const activeLang = useContext(LanguageContext)
  const { getPath } = useTranslation()

  const {
    siteUrl,
    siteName,
    facebookUrl,
    instagramUrl,
    youTubeUrl,
    linkedInUrl,
    twitterHandle,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          siteName
          facebookUrl
          instagramUrl
          youTubeUrl
          linkedInUrl
          twitterHandle
        }
      }
    }
  `).site.siteMetadata

  const alternates = languages
    .filter(l => l !== activeLang)
    .map((l, i) => (
      <link
        key={i}
        rel="alternate"
        hrefLang={l}
        href={`${siteUrl}${getPath(l, pageName)}`}
      />
    ))

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: activeLang,
        }}
        meta={[
          { name: "description", content: description },
          { name: "image", content: PreviewImage },
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:description", content: description },
          { name: "twitter:title", content: `${title} | ${siteName}` },
          { name: "twitter:image", content: `${siteUrl}${PreviewImage}` },
          { name: "twitter:site", content: twitterHandle },
          { property: "og:title", content: `${title} | ${siteName}` },
          { property: "og:description", content: description },
          { property: "og:type", content: "website" },
          {
            property: "og:url",
            content: `${siteUrl}${getPath(activeLang, pageName)}`,
          },
          { property: "og:image", content: `${siteUrl}${PreviewImage}` },
          { property: "og:locale", content: `${activeLang}` },
          { property: "og:image:url", content: `${siteUrl}${PreviewImage}` },
        ]}
        title={title}
        titleTemplate={`%s | ${siteName} (${activeLang.toUpperCase()})`}
      >
        {pageName !== undefined && (
          <link
            rel="canonical"
            href={`${siteUrl}${getPath(activeLang, pageName)}`}
          />
        )}
        {pageName !== undefined && alternates}
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Corporation",
          "url": "${siteUrl}",
          "name": "${siteName}",
          "sameAs": [
            "${facebookUrl}",
            "${instagramUrl}",
            "${youTubeUrl}",
            "${linkedInUrl}"
          ],
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Am Schmidinger Weiher 3",
            "addressLocality": "Ainring",
            "postalCode": "83404",
            "addressCountry": "DE"
          },
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+49 8654 6824 440",
            "contactType": "sales",
            "areaServed": "150",
            "availableLanguage": ["en", "German"]
          },{
            "@type": "ContactPoint",
            "telephone": "(815) 962-3011",
            "contactType": "sales",
            "areaServed": "US",
            "availableLanguage": "en"
          }]
        }
      `}
        </script>
      </Helmet>
      {children}
    </>
  )
}

export default SEO
