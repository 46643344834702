import React from "react"
import { Link } from "gatsby"

import SEO from "../components/SEO"
import useTranslation from "../translations/translate"

const PrivacyPolicy = ({ pageContext: { pageName } }) => {
  const { t, getLocalizedPath } = useTranslation()

  return (
    <>
      <SEO
        title="Privacy policy"
        description="The protection of your data has top priority when you visit our website."
        pageName={pageName}
        noIndex
      />
      <section className="container content d-flex">
        <div className="row w-100 my-auto flex-column-reverse flex-md-row">
          <div className="col-12">
            <div className="mb-5">
              <Link to={getLocalizedPath("/")} className="text-decoration-none">
                ← {t("common.back")}
              </Link>
            </div>

            <h1 className="mb-5">{t("page.privacy.title")}</h1>

            <p className="lead">
              <span className="fw-bold">Angaben gemäß § 5 TMG</span>
              <br />
              EVOBEND
              <br />
              Am Schmidinger Weiher 3<br />
              D-83404 Ainring
            </p>

            <p className="lead">
              <span className="fw-bold">Vertreten durch</span>
              <br />
              Emanuel Allerberger
            </p>

            <p className="lead">
              <span className="fw-bold">Kontakt</span>
              <br />
              Telefon: +49 157 8302 1032
              <br />
              E-Mail: info@evobend.com
              <br />
              www.evobend.com
            </p>

            <p className="lead">
              <span className="fw-bold">Registereintrag</span>
              <br />
              Eintragung im Handelsregister.
              <br />
              Handelregister: HR B 26754
              <br />
              Registergericht: Traunstein
            </p>

            <p className="lead">
              <span className="fw-bold">Umsatzsteuer</span>
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a
              <br />
              Umsatzsteuer ID: DE 316 813 238
            </p>

            <p className="lead">
              <span className="fw-bold">Streitschlichtung</span>
              <br />
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstellte
              teilzunehmen.
            </p>

            <p className="lead">
              <span className="fw-bold">Haftung für Inhalte</span>
              <br />
              Als Dienstanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Dienstanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
              Entfernung oder Sperrung der Nutzung von Informationen nach den
              allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>

            <p className="lead">
              <span className="fw-bold">Haftung für Links</span>
              <br />
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>

            <p className="lead">
              <span className="fw-bold">Urheberrecht</span>
              <br />
              Die durch den Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy
